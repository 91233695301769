<template>
  <div v-if="!loading && channels.length" class="main">

    <div class="tabs">
      {{ account.name }}

    </div>

    <div class="tabs">
      <label class="mainLabel">
        <span>Channels</span>
        <select v-model="selectedChannel">
          <option :value="0">All</option>
          <option :value="`${elem.channelID}`" v-for="elem in channels">{{ elem.name }}</option>
        </select>
      </label>

      <label class="mainLabel" :class="{ disabledElem: selectedChannel == 0 }">
        <span>Links</span>
        <select v-model="selectedLink">
          <option :value="0">All</option>
          <option :value="`${elem.linkID}`" v-for="elem in linksInChannels">{{ elem.link_name }}</option>
        </select>
      </label>

    </div>

    <div class="tabs">

      <label class="mainLabel">
        <span>Date From</span>
        <input type="date" v-model="date1" :max="date2">
      </label>

      <label class="mainLabel">
        <span>Date To</span>
        <input type="date" v-model="date2" :min="date1">
      </label>

    </div>

    <div class="controlDate">
      <button @click="setToday">Сегодня</button>
      <button @click="setYesterday">Вчера</button>
      <button @click="setLast7Days">Последние 7 дней</button>
      <button @click="setLastWeek">Прошлая неделя</button>
      <button @click="setThisWeek">Эта неделя</button>
      <button @click="setThisMonth">Этот месяц</button>
      <button @click="setLastMonth">Прошлый месяц</button>
      <button @click="setLast30Days">Последние 30 дней</button>
      <button @click="setLast60Days">Последние 60 дней</button>
    </div>

    <div class="tabs adaptiv" style="justify-content: flex-end;" :class="{ disabledElem: selectedChannel == 0 }">
      <div>
        Total in channel
      </div>
      <div class="totalInfo unsub">
        <span>Unsubs</span>
        <span>{{ totalInChannelUnsubs().length }}</span>
      </div>

      <div class="totalInfo sub">
        <span>Subs</span>
        <span>{{ totalInChannelSubs().length }}</span>
      </div>
    </div>

    <div class="tabs adaptiv" style="justify-content: flex-end;">
      <div>
        Total in date
      </div>
      <div class="totalInfo unsub">
        <span>Unsubs</span>
        <span>{{ totalUnsubsDiapazon().length }}</span>
      </div>

      <div class="totalInfo sub">
        <span>Subs</span>
        <span>{{ totalSubsDiapazon().length }}</span>
      </div>
    </div>

    <table v-if="selectedChannel == 0">
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th class="unsub">Unsubs</th>
          <th class="sub">Subs</th>
          <th>Members Now</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!channels.length">
          <td style="text-align: center;" colspan="7">Channels not found</td>
        </tr>
        <tr v-else v-for="(elem, index, count) in channels">
          <td style="text-align: center;">{{ index + 1 }}</td>
          <td>{{ elem.name }}</td>
          <td style="text-align: center;">{{ unSubsInDiapazon(elem.channelID).length }}</td>
          <td style="text-align: center;">{{ subsInDiapazon(elem.channelID).length }}</td>
          <td style="text-align: center;">{{ subsInDiapazon(elem.channelID).length -
            unSubsInDiapazon(elem.channelID).length }}</td>
        </tr>
      </tbody>
    </table>

    <table v-else-if="selectedLink == 0">
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Link</th>
          <th class="unsub">Unsubs</th>
          <th class="sub">Subs</th>
          <th>Members Now</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!linksInChannels.length">
          <td style="text-align: center;" colspan="7">Links not found</td>
        </tr>
        <tr v-else v-for="(elem, index, count) in linksInChannels">
          <td style="text-align: center;">{{ index + 1 }}</td>
          <td>{{ elem.link_name }}</td>
          <td>{{ elem.link }}</td>
          <td style="text-align: center;">{{ unSubsInDiapazon(elem.linkID).length }}</td>
          <td style="text-align: center;">{{ subsInDiapazon(elem.linkID).length }}</td>
          <td style="text-align: center;">{{ subsInDiapazon(elem.linkID).length - unSubsInDiapazon(elem.linkID).length }}
          </td>
        </tr>
      </tbody>
    </table>

    <table v-else>
      <thead>
        <tr>
          <th>#</th>
          <th>userID</th>
          <th>username</th>
          <th>status</th>
          <th>date</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!subsAndUnsubsInLink.length">
          <td style="text-align: center;" colspan="7">Subs or Unsubs not found</td>
        </tr>
        <tr v-else v-for="(elem, index, count) in subsAndUnsubsInLink">
          <td style="text-align: center;">{{ index + 1 }}</td>
          <td>{{ elem.userID }}</td>
          <td>{{ elem.username }}</td>
          <td style="text-align: center;">{{ elem.status }}</td>
          <td style="text-align: center;">{{ getFormatDate(elem.dateAdd) }}</td>
        </tr>
      </tbody>
    </table>

  </div>
  <div v-else-if="loading" class="loadingBlock">
    <div class="loading">Loading&#8230;</div>
  </div>

  <div v-if="!channels.length" class="error">
    Channels not found
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      api: "/api.php?getBotData", // Запрос на лиды к файлу PHP

      loading: true,

      channels: [],
      links: [],
      subs: [],
      unsubs: [],
      account: null,

      date1: null,
      date2: null,

      selectedChannel: null,
      selectedLink: null,
    }
  },
  components: {
  },
  mounted() {
    const today = new Date().toISOString().split('T')[0];
    this.date1 = today;
    this.date2 = today;

    this.getBotData()
      .then(res => {
        this.selectedChannel = 0;
      })
      .then(res => {
        this.selectedLink = 0;
      })
      .then(res => {
        this.loading = false;
      })
  },
  computed: {
    linksInChannels() {
      return this.links.filter(elem => elem.channelID == this.selectedChannel);
    },
    subsAndUnsubs() {
      const combinedArray = [...this.subs, ...this.unsubs];
      return combinedArray;
    },
    subsAndUnsubsInLink() {
      return this.subsAndUnsubs.filter(elem => elem.channelID == this.selectedChannel && elem.linkID == this.selectedLink && this.isDateInRange(elem.dateAdd));
    },
  },
  watch: {
    selectedChannel() {
      this.selectedLink = 0;
    }
  },
  methods: {
    setToday() {
      const today = new Date().toISOString().split('T')[0];
      this.date1 = today;
      this.date2 = today;
    },
    setYesterday() {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const formattedYesterday = yesterday.toISOString().split('T')[0];
      this.date1 = formattedYesterday;
      this.date2 = formattedYesterday;
    },
    setLast7Days() {
      const endDate = new Date().toISOString().split('T')[0];
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 6);
      const formattedStartDate = startDate.toISOString().split('T')[0];
      this.date1 = formattedStartDate;
      this.date2 = endDate;
    },
    setThisWeek() {
      const today = new Date();
      const currentDay = today.getDay();
      const diffMonday = today.getDate() - currentDay + (currentDay === 0 ? -6 : 1) + 1; // Если сегодня воскресенье, то начало недели - 6 дней назад
      const diffSunday = diffMonday + 5; // Плюс 6 дней от понедельника даст воскресенье
      const monday = new Date(today.setDate(diffMonday));
      const sunday = new Date(today.setDate(diffSunday));
      this.date1 = monday.toISOString().split('T')[0];
      this.date2 = new Date(sunday.getFullYear(), sunday.getMonth(), sunday.getDate() + 1).toISOString().split('T')[0]; // добавляем один день
    },
    setLastWeek() {
      const today = new Date();
      const currentDay = today.getDay();
      const diff = today.getDate() - currentDay - 7 + (currentDay === 0 ? -6 : 1) + 1; // Если сегодня воскресенье, то начало предыдущей недели - 13 дней назад
      const monday = new Date(today.setDate(diff));
      const sunday = new Date(today.setDate(diff + 6));
      this.date1 = monday.toISOString().split('T')[0];
      this.date2 = new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() + 6).toISOString().split('T')[0]; // конец прошлой недели
    },
    setThisMonth() {
      const today = new Date();
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      this.date1 = firstDayOfMonth.toISOString().split('T')[0];
      this.date2 = lastDayOfMonth.toISOString().split('T')[0];
    },
    setLastMonth() {
      const today = new Date();
      const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 2);
      const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      this.date1 = firstDayOfLastMonth.toISOString().split('T')[0];
      this.date2 = lastDayOfLastMonth.toISOString().split('T')[0];
    },
    setLast30Days() {
      const endDate = new Date().toISOString().split('T')[0];
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 30);
      const formattedStartDate = startDate.toISOString().split('T')[0];
      this.date1 = formattedStartDate;
      this.date2 = endDate;
    },
    setLast60Days() {
      const endDate = new Date().toISOString().split('T')[0];
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 60);
      const formattedStartDate = startDate.toISOString().split('T')[0];
      this.date1 = formattedStartDate;
      this.date2 = endDate;
    },


    getFormatDate(dateDB) {
      const date = new Date(dateDB);

      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Месяцы в JavaScript начинаются с 0
      const year = date.getFullYear();

      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');

      return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    },
    subsInLink(linkID) {
      return this.subs.filter(elem => elem.channelID == this.selectedChannel && elem.linkID == linkID);
    },
    subsInChannel(channelID) {
      return this.subs.filter(elem => elem.channelID == channelID);
    },
    subsInDiapazon(linkID) {
      if (this.selectedChannel == 0) return this.subsInChannel(linkID).filter(elem => this.isDateInRange(elem.dateAdd));
      return this.subsInLink(linkID).filter(elem => this.isDateInRange(elem.dateAdd));
    },
    unSubsInLink(linkID) {
      return this.unsubs.filter(elem => elem.channelID == this.selectedChannel && elem.linkID == linkID);
    },

    unSubsInChannel(channelID) {
      return this.unsubs.filter(elem => elem.channelID == channelID);
    },

    unSubsInDiapazon(linkID) {
      if (this.selectedChannel == 0) return this.unSubsInChannel(linkID).filter(elem => this.isDateInRange(elem.dateAdd));
      return this.unSubsInLink(linkID).filter(elem => this.isDateInRange(elem.dateAdd));
    },

    totalInChannelSubs() {
      return this.subs.filter(elem => elem.channelID == this.selectedChannel);
    },

    totalInChannelUnsubs() {
      return this.unsubs.filter(elem => elem.channelID == this.selectedChannel);
    },

    totalSubsDiapazon() {
      // Все каналы
      if (this.selectedChannel == 0) {
        return this.subs.filter(elem => this.isDateInRange(elem.dateAdd));
      } else {
        if (this.selectedLink == 0) {
          return this.subs.filter(elem => this.isDateInRange(elem.dateAdd) && elem.channelID == this.selectedChannel);
        } else {
          return this.subs.filter(elem => this.isDateInRange(elem.dateAdd) && elem.channelID == this.selectedChannel && elem.linkID == this.selectedLink);
        }
      }
    },

    totalUnsubsDiapazon() {
      // Все каналы
      if (this.selectedChannel == 0) {
        return this.unsubs.filter(elem => this.isDateInRange(elem.dateAdd));
      } else {
        if (this.selectedLink == 0) {
          return this.unsubs.filter(elem => this.isDateInRange(elem.dateAdd) && elem.channelID == this.selectedChannel);
        } else {
          return this.unsubs.filter(elem => this.isDateInRange(elem.dateAdd) && elem.channelID == this.selectedChannel && elem.linkID == this.selectedLink);
        }
      }
    },

    isDateInRange(selectedDate) {
      const selected = new Date(selectedDate);
      const start = new Date(this.date1);
      const end = new Date(this.date2);

      // Установим время на конец дня для корректного сравнения
      selected.setHours(23, 59, 59, 999);
      start.setHours(23, 59, 59, 999);
      end.setHours(23, 59, 59, 999);

      return selected >= start && selected <= end;
    },

    async getBotData() {

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const paramValue = urlParams.get('access');

      try {
        const response = await axios.post(
          this.api + "&access=" + paramValue,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

        if (!response.data) {
          return alert('Ошибка при запросе данных');
        }

        this.channels = response.data.channels;
        this.links = response.data.links;
        this.subs = response.data.subs;
        this.unsubs = response.data.unsubs;
        this.account = response.data.admin;

      } catch (e) {
        alert('Ошибка при запросе данных');
        alert(e);
        return false;
      }
    },
  }
}
</script>
<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Segoe, Tahoma, Geneva, sans-serif;
}

#app {
  padding: 10px;
  max-width: 1100px;
  margin: 0 auto;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main {
  overflow: auto;
  width: 100%;
}

.label {
  display: flex;
  flex-direction: column;
}

.label input {
  padding: 3px;
  font-size: 15px;
  border: none;
  background: #e1e1e1a3;
  border-radius: 5px;
}

table {
  white-space: nowrap;
  margin-top: 20px;
  overflow: auto;
  display: block;
}

thead {
  background-color: #ececec;
  border-radius: 5px;
}

thead th {
  padding: 5px;
  width: 10%;
}


tbody td {
  padding: 0 10px;
}

.tabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}



.totalInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #dddddd;
  border-radius: 5px;
  padding: 5px;
  gap: 15px;
  width: 170px;
  justify-content: space-between;
}


.sub {
  font-weight: bold;
  color: green;
}

.unsub {
  font-weight: bold;
  color: red;
}


@media (max-width: 540px) {
  .adaptiv {
    flex-direction: column;
  }

}


/* loading */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.mainLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mainLabel span {
  font-size: 13px;
  font-weight: bold;
}

select {
  padding: 5px;
  background: #dddddd;
  border: none;
  border-radius: 5px;
  transition: all .3s;
  cursor: pointer;
}

select:hover,
input[type="date"]:hover {
  opacity: .5;
}

.tabs {
  gap: 10px;
}

input[type="date"] {
  padding: 5px;
  background: #dddddd;
  border: none;
  border-radius: 5px;
  transition: all .3s;
  cursor: pointer;
}

.error {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  padding-top: 100px;
  z-index: 9999999999;
  text-align: center;
}

.disabledElem {
  opacity: .3;
  pointer-events: none;
  filter: blur(1px);
}

.controlDate {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  gap: 10px;
}

.controlDate button {
  background: #dddddd;
  border: none;
  padding: 5px;
  border-radius: 5px;
  transition: all .3s;
  cursor: pointer;
}

.controlDate button:hover {
  opacity: .5;
}
</style>
